import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Alert, AlertTitle, Collapse} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {socket} from "./socket";

const SosAlert = () => {
    const [sos, setSos] = useState([]);

    const fetchSos = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sos`);
        return response.data;
    }

    const dismiss = (id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/sos/dismiss`, {id: id}).then(() => {
            fetchSos().then((data) => {
                setSos(data);
            });
        })
    }

    useEffect(() => {
        fetchSos().then((data) => {
            setSos(data);
        })
    }, [])

    useEffect(() => {
        socket.connect();

        socket.on('connection', () => {
            console.log('WS: connected');
        });

        socket.on('sos', (data) => {
            fetchSos().then((data) => {
                setSos(data);
            })
        });
    }, []);

    return (
        <>
            {sos.map((sos) => {
                return (
                    <Box sx={{width: '100%'}}>
                        <Collapse in={true}>
                            <Alert
                                severity="error"
                                variant={"outlined"}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            dismiss(sos.id)
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit"/>
                                    </IconButton>
                                }
                                sx={{mb: 2, bgcolor: 'red', color: "white"}}
                            >
                                <AlertTitle>{sos.user.first_name} {sos.user.last_name} žádá o pomoc!</AlertTitle>
                                {sos.user.phone}
                            </Alert>
                        </Collapse>
                    </Box>
                )
            })}
        </>
    )
}

export default SosAlert;