import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import axios from 'axios';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";
import {Button, Table, TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: '#FFC005', // Change the background color here
    color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const defaultTheme = createTheme();

export default function MonthlyTablePage() {
    const [open, setOpen] = useState(true);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [earnings, setEarnings] = useState(0);

    const [data, setData] = useState([{name: null, price: 0}]);
    const name = useRef();
    const price = useRef();

    const handleAdd = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/admins/addMonthlyData`, {
            month,
            year,
            name: name.current.value,
            price: parseFloat(price.current.value)
        }).then(() => {
            fetchEarnings().then(data => setEarnings(data));
            fetchMonthlyData().then(data => setData(data));

            name.current.value = '';
            price.current.value = '';
        });
    }

    const handleDelete = (id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/admins/deleteMonthlyData`, {id}).then(() => {
            fetchEarnings().then(data => setEarnings(data));
            fetchMonthlyData().then(data => setData(data));
        });
    }

    const fetchEarnings = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admins/earnings`, {month, year});
        return response.data;
    }

    const fetchMonthlyData = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admins/monthlyData`, {month, year});
        return response.data;
    }

    useEffect(() => {
        fetchEarnings().then(data => setEarnings(data));
        fetchMonthlyData().then(data => setData(data));
    }, []);

    useEffect(() => {
        fetchEarnings().then(data => setEarnings(data));
        fetchMonthlyData().then(data => setData(data));
    }, [month, year]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position='absolute' open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge='start'
                            color='inherit'
                            aria-label='open drawer'
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            component='h1'
                            variant='h6'
                            color='inherit'
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            Admin panel
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant='permanent' open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component='nav'>
                        {mainListItems}
                        <Divider sx={{my: 1}}/>
                        {secondaryListItems}
                    </List>
                </Drawer>
                <Box
                    component='main'
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>

                    <SosAlert/>

                    <Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
                        <div>
                            <div className='createOrderContainer' style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}>
                                <select className='input-element' defaultValue={new Date().getMonth() + 1}
                                        onChange={(e) => setMonth(e.target.value)}>
                                    <option value="1">Leden</option>
                                    <option value="2">Únor</option>
                                    <option value="3">Březen</option>
                                    <option value="4">Duben</option>
                                    <option value="5">Květen</option>
                                    <option value="6">Červen</option>
                                    <option value="7">Červenec</option>
                                    <option value="8">Srpen</option>
                                    <option value="9">Září</option>
                                    <option value="10">Říjen</option>
                                    <option value="11">Listopad</option>
                                    <option value="12">Prosinec</option>
                                </select>

                                <input className='input-element' onChange={(e) => setYear(e.target.value)} type="number"
                                       min={1}
                                       max={new Date().getFullYear()} step={1} defaultValue={new Date().getFullYear()}/>

                                <Button variant={"contained"}>Ukázat</Button>
                            </div>


                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Název</b></TableCell>
                                        <TableCell><b>Částka (Kč)</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Příjmy</TableCell>
                                        <TableCell>{earnings.earnings}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>

                                    {data.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.price}</TableCell>
                                                <TableCell><Button
                                                    onClick={() => handleDelete(row.id)}>-</Button></TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    <TableRow>
                                        <TableCell><input style={{
                                            border: 'none',
                                            padding: '5px',
                                            width: '100%'

                                        }} ref={name} type={"text"}
                                                          placeholder={"Název..."}/></TableCell>
                                        <TableCell><input style={{
                                            border: 'none',
                                            padding: '5px',

                                        }} ref={price} type={"number"}/><Button variant={"contained"}
                                                                                onClick={handleAdd}>+</Button></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><b>Celkem: </b></TableCell>
                                        <TableCell>{earnings.earnings + data.reduce((prev, cur) => prev + cur.price, 0)}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>

                        <Copyright sx={{pt: 4}}/>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
