import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {
	AdminsPage,
	BlacklistPage,
	CreateOrderPage,
	CustomersPage,
	DashboardPage,
	DeleteRequest,
	DriversPage,
	HistoryLoginPage,
	HomePage,
	LoginPage,
	MapPage,
	MonthlyTablePage,
	NotificationPage,
	OrdersPage,
	PrivacyPage,
	TermsOfConditionsPage,
	VipPage,
	GalleryPage,
} from './pages';
import PrivateRoute from './components/PrivateRoute';

function App() {
	return (
		<div className='App'>
			<Router>
				<Routes>
					<Route exact path='/' element={<HomePage />} />
					<Route exact path='/login' element={<LoginPage />} />
					<Route exact path='/galerie' element={<GalleryPage />} />
					<Route exact path='/privacy-policy' element={<PrivacyPage />} />
					<Route
						exact
						path='/terms-and-conditions'
						element={<TermsOfConditionsPage />}
					/>
					<Route exact path='/delete-request' element={<DeleteRequest />} />
					<Route
						exact
						path='/admin/history-login'
						element={<HistoryLoginPage />}
					/>
					<Route
						exact
						path='/admin/monthly-table'
						element={<MonthlyTablePage />}
					/>
					<Route
						exact
						path='/admin/vip'
						element={
							<PrivateRoute>
								<VipPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/customers'
						element={
							<PrivateRoute>
								<CustomersPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/notifications'
						element={
							<PrivateRoute>
								<NotificationPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/drivers'
						element={
							<PrivateRoute>
								<DriversPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/admins'
						element={
							<PrivateRoute>
								<AdminsPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/map'
						element={
							<PrivateRoute>
								<MapPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/orders/create'
						element={
							<PrivateRoute>
								<CreateOrderPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/blacklist'
						element={
							<PrivateRoute>
								<BlacklistPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/dashboard'
						element={
							<PrivateRoute>
								<DashboardPage />
							</PrivateRoute>
						}
					/>
					<Route
						exact
						path='/admin/orders'
						element={
							<PrivateRoute>
								<OrdersPage />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
