import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

function preventDefault(event) {
	event.preventDefault();
}

export default function Orders() {
	const [orders, setOrders] = useState([]);

	const fetchOrders = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/order`
		);
		return response.data;
	};

	const statusTranslations = {
		waiting: 'čeká',
		accepted: 'přijmuto',
		done: 'hotovo',
		declined: 'odmítnuta',
	};

	useEffect(() => {
		fetchOrders()
			.then((data) => {
				const sortedOrders = data.sort(
					(a, b) => new Date(b.ride.createdAt) - new Date(a.ride.createdAt)
				);
				setOrders(sortedOrders);
			})
			.catch((e) => console.log('Orders: ' + e));
	}, []);

	return (
		<React.Fragment>
			<Typography component='h2' variant='h5' color='#444444' gutterBottom>
				<b>Objednávky</b>
			</Typography>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell>Datum</TableCell>
						<TableCell>Jméno zákazníka</TableCell>
						<TableCell>Odkud</TableCell>
						<TableCell>Kam</TableCell>
						<TableCell></TableCell>
						<TableCell>Délka</TableCell>
						<TableCell>Status</TableCell>
						<TableCell align='right'>Cena</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{orders.slice(0, 8).map((order) => (
						<TableRow key={order.id}>
							<TableCell>
								{new Date(order.ride.createdAt).toLocaleString('cs-CZ', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								})}
							</TableCell>
							<TableCell>
								{' '}
								{order?.user?.first_name || 'Dispečink'}{' '}
								{order?.user?.last_name || ''}
							</TableCell>
							<TableCell>
								{JSON.parse(order.ride.pickup_location)
									.name.split(',')[0]
									.trim()}
							</TableCell>
							<TableCell>
								{JSON.parse(order.ride.destination).name.split(',')[0].trim()}
							</TableCell>
							<TableCell>{order.ride.isPreorder ? <span style={{color: "red"}}>Předobjednávka!</span> : null}</TableCell>
							<TableCell>{order.ride.distance} km</TableCell>
							<TableCell className={order.ride.status.toLowerCase()}>
								{' '}
								<b>{statusTranslations[order.ride.status.toLowerCase()]}</b>
							</TableCell>
							<TableCell align='right'>{`${order.ride.price} Kč`}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<a href='/admin/orders' style={{ marginTop: '10px' }}>
				Zobrazit více objednávek
			</a>
		</React.Fragment>
	);
}
