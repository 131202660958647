import axios from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import Autocomplete from 'react-google-autocomplete';
import {v4 as uuidv4} from 'uuid';

import {DirectionsRenderer, GoogleMap, useJsApiLoader,} from '@react-google-maps/api';
import {useNavigate} from 'react-router-dom';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";

const {
	MarkerWithLabel,
} = require('react-google-maps/lib/components/addons/MarkerWithLabel');

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#FFC005', // Change the background color here
	color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const defaultTheme = createTheme();

const CreateOrderPage = () => {
	const [open, setOpen] = React.useState(true);
	const [origin, setOrigin] = useState(null);
	const [destination, setDestination] = useState(null);
	const [routeInfo, setRouteInfo] = useState(null);
	const [isInCity, setIsInCity] = useState(false);
	const [price, setPrice] = useState(null);
	const [name, setName] = useState(null);
	const [phone, setPhone] = useState(null);
	const [people, setPeople] = useState(1);

	const [drivers, setDrivers] = useState(null);
	const [selectDriver, setSelectDriver] = useState(-1);

	const navigate = useNavigate();

	const fetchDrivers = async () => {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/driver/`);
		return response.data;
	}

	const sendNotificationToDrivers = async () => {
		axios.get(
			`${process.env.REACT_APP_API_URL}/api/admins/getNotificationTokensOfDrivers/`
		);
	};

	const sendNotificationToDriver = async () => {
		axios.get(
			`${process.env.REACT_APP_API_URL}/api/admins/getNotificationTokensOfDriver/${selectDriver}`
		);
	};

	const sendOrder = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/api/admins/order/create`, {
				pickup_location: {...origin},
				destination: {...destination},
				distance:
					Math.round((routeInfo.distance / 1000 + Number.EPSILON) * 10) / 10,
				name: name,
				phone: phone,
				price: price,
				people: people,
				duration: Math.round(routeInfo.duration / 60),
				token: uuidv4(),
				id_driver: selectDriver,
				driver_name: drivers.filter((driver) => driver.id === selectDriver)[0].user.last_name
			})
			.then(() => {
				if (selectDriver !== -1) {
					sendNotificationToDriver();
				} else {
					sendNotificationToDrivers();
				}

				navigate('/admin/orders');
			})
			.catch((e) => console.log('Order success, create: ' + e));
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
		libraries: ['places'],
	});

	const [map, setMap] = useState(null);
	const [directionsResponse, setDirectionsResponse] = useState(null);

	async function calculateRoute() {
		const directionsService = new window.google.maps.DirectionsService();
		const results = await directionsService.route({
			origin: origin,
			destination: destination,
			travelMode: window.google.maps.TravelMode.DRIVING,
		});
		setDirectionsResponse(results);

		if (origin.name.includes('Praha') && destination.name.includes('Praha')) {
			setIsInCity(true);
		} else {
			setIsInCity(false);
		}

		if (results && results.routes && results.routes.length > 0) {
			const selectedRoute = results.routes[0]; // Use the first route (the primary route)
			const durationInSeconds = selectedRoute.legs.reduce(
				(total, leg) => total + leg.duration.value,
				0
			);
			const distanceInMeters = selectedRoute.legs.reduce(
				(total, leg) => total + leg.distance.value,
				0
			);

			// Store the route information in the state
			setRouteInfo({
				duration: durationInSeconds,
				distance: distanceInMeters,
			});
		}
	}

	const toggleDrawer = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (!routeInfo) return;

		if (isInCity && people < 5) {
			setPrice(50);
		} else if (isInCity && people >= 5) {
			setPrice(60);
		} else if (!isInCity && people < 4) {
			setPrice(
				Math.round(
					(Math.round((routeInfo.distance / 1000 + Number.EPSILON) * 10) / 10) *
						28 +
						40 +
						Number.EPSILON
				)
			); //These round price to whole number
		} else if (!isInCity && people >= 4) {
			setPrice(
				Math.round(
					(Math.round((routeInfo.distance / 1000 + Number.EPSILON) * 10) / 10) *
						33 +
						40 +
					Number.EPSILON
				)
			);
		}
	}, [routeInfo, isInCity, people]);

	useEffect(() => {
		if (!origin || !destination) return;
		calculateRoute();
	}, [origin, destination]);

	useEffect(() => {
		fetchDrivers().then((data) => {
			setDrivers(data);
		})
	}, [])

	if (!isLoaded) return <></>;

	return isLoaded ? (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{display: 'flex'}}>
					<CssBaseline/>
					<AppBar position='absolute' open={open}>
						<Toolbar
							sx={{
								pr: '24px', // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='open drawer'
								onClick={toggleDrawer}
								sx={{
									marginRight: '36px',
									...(open && { display: 'none' }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								component='h1'
								variant='h6'
								color='inherit'
								noWrap
								sx={{ flexGrow: 1 }}
							>
								Vytvořit objednávku
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer variant='permanent' open={open}>
						<Toolbar
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<List component='nav'>
							{mainListItems}
							<Divider sx={{ my: 1 }} />
							{secondaryListItems}
						</List>
					</Drawer>
					<Box
						component='main'
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === 'light'
									? theme.palette.grey[100]
									: theme.palette.grey[900],
							flexGrow: 1,
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Toolbar/>

						<SosAlert/>

						<Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
							<div className='createOrderContainer'>
								<p className={"m-0 p-0"}>Odkud:</p>
								<Autocomplete
									className='autocomplete-container'
									apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
									onPlaceSelected={(value) =>
										setOrigin({
											lat: value.geometry.location.lat(),
											lng: value.geometry.location.lng(),
											name: value.formatted_address,
										})
									}
									options={{
										types: ['establishment', 'geocode'], // Only autocomplete addresses (streets)
										componentRestrictions: { country: 'cz' },
										language: 'cs',
										region: 'CZ',
									}}
									placeholder='Počátek'
								/>

								<p className={"m-0 p-0"}>Kam:</p>
								<Autocomplete
									className='autocomplete-container'
									apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
									onPlaceSelected={(value) =>
										setDestination({
											lat: value.geometry.location.lat(),
											lng: value.geometry.location.lng(),
											name: value.formatted_address,
										})
									}
									options={{
										types: ['establishment', 'geocode'], // Only autocomplete addresses (streets)
										componentRestrictions: { country: 'cz' },
										language: 'cs',
										region: 'CZ',
									}}
									placeholder='Destinace'
								/>

								<p className={"m-0 p-0"}>Jméno zákazníka:</p>
								<input
									className='input-element'
									onChange={(e) => setName(e.target.value)}
									placeholder='Jméno'
								/>

								<p className={"m-0 p-0"}>Telefon:</p>
								<input
									className='input-element'
									onChange={(e) => setPhone(e.target.value)}
									placeholder='Telefon'
								/>

								<p className={"m-0 p-0"}>Počet osob:</p>
								<input
									className='input-element'
									onChange={(e) => setPeople(parseInt(e.target.value))}
									placeholder='Počet osob'
									required
								/>

								<p className={"m-0 p-0"}>Řidič</p>
								<select
									style={{backgroundColor: "white"}}
									className='input-element'
									onChange={(e) => setSelectDriver(parseInt(e.target.value))}
									required
								>
									<option value={-1}>Žádný</option>
									{drivers && drivers.map((driver) => {
										return (
											<option value={driver.id}>{driver.user.last_name}</option>
										)
									})}
								</select>

								<button className='button-element' onClick={sendOrder}>
									Odeslat
								</button>
							</div>

							{routeInfo && price && (
								<div>
									<p>Cena: {price} Kč</p>
									<p>
										Vzdálenost:{' '}
										{Math.round(
											(routeInfo.distance / 1000 + Number.EPSILON) * 10
										) / 10}{' '}
										km
									</p>
									<p>Čas: {Math.round(routeInfo.duration / 60)} min</p>
								</div>
							)}

							{origin && destination && (
								<GoogleMap
									center={origin}
									zoom={5}
									mapContainerStyle={{width: '100%', height: '75vh'}}
									options={{
										zoomControl: false,
										streetViewControl: false,
										mapTypeControl: false,
										fullscreenControl: false,
									}}
									onLoad={(map) => setMap(map)}
								>
									{directionsResponse && (
										<DirectionsRenderer directions={directionsResponse} />
									)}
								</GoogleMap>
							)}

							<Copyright sx={{ pt: 4 }} />
						</Container>
					</Box>
				</Box>
			</ThemeProvider>
		</>
	) : (
		<></>
	);
};

export default CreateOrderPage;
