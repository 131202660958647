import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MapIcon from '@mui/icons-material/Map';
import LogoutIcon from '@mui/icons-material/Logout';
import WorkIcon from '@mui/icons-material/Work';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import {Link as RouterLink} from 'react-router-dom'; // Import from React Router if you're using it
import Cookies from 'js-cookie';
import axios from "axios";

export const mainListItems = (
	<React.Fragment>
		<ListItemButton component={RouterLink} to='/admin/dashboard'>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary='Admin panel' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/orders'>
			<ListItemIcon>
				<ShoppingCartIcon />
			</ListItemIcon>
			<ListItemText primary='Objednávky' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/orders/create'>
			<ListItemIcon>
				<AddIcon />
			</ListItemIcon>
			<ListItemText primary='Vytvořit objednávku' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/map'>
			<ListItemIcon>
				<MapIcon />
			</ListItemIcon>
			<ListItemText primary='Mapa' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/customers'>
			<ListItemIcon>
				<PeopleIcon />
			</ListItemIcon>
			<ListItemText primary='Zákazníci' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/blacklist'>
			<ListItemIcon>
				<SecurityUpdateWarningIcon />
			</ListItemIcon>
			<ListItemText primary='Blacklist' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/admins'>
			<ListItemIcon>
				<AssignmentIndIcon />
			</ListItemIcon>
			<ListItemText primary='Administrátoři' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/vip'>
			<ListItemIcon>
				<LocalActivityIcon />
			</ListItemIcon>
			<ListItemText primary='VIP zákazníci' />
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/notifications'>
			<ListItemIcon>
				<NotificationsActiveIcon />
			</ListItemIcon>
			<ListItemText primary='Oznámení'/>
		</ListItemButton>

		<ListItemButton component={RouterLink} to='/admin/drivers'>
			<ListItemIcon>
				<WorkIcon/>
            </ListItemIcon>
            <ListItemText primary='Řidiči'/>
        </ListItemButton>

        <ListItemButton component={RouterLink} to='/admin/history-login'>
            <ListItemIcon>
                <LockOpenIcon/>
            </ListItemIcon>
            <ListItemText primary='Historie přihlášení'/>
        </ListItemButton>

        <ListItemButton component={RouterLink} to='/admin/monthly-table'>
            <ListItemIcon>
                <ArticleIcon/>
            </ListItemIcon>
            <ListItemText primary='Měsíční tabulka'/>
        </ListItemButton>
    </React.Fragment>
);

const logout = () => {
	axios.post(`${process.env.REACT_APP_API_URL}/api/history_login/create`, {token: Cookies.get("token")}).then(r => {
		Cookies.remove('token');
		window.location.href = '/login';
	});
}

export const secondaryListItems = (
	<React.Fragment>
		<ListItemButton onClick={logout}>
			<ListItemIcon>
				<LogoutIcon />
			</ListItemIcon>
			<ListItemText primary='Odhlásit se' />
		</ListItemButton>
	</React.Fragment>
);
