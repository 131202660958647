import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';

export default function Deposits() {
	const [balance, setBalance] = useState(0);

	const getBalance = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/payment/${Cookies.get("token")}/balance`).then((res) => {
			setBalance(res.data)
			console.log(res.data)
		})
	}

	useEffect(() => {
		getBalance()
	}, []);

	return (
		<>
			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>24 hodin</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balanceDay}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>Víkend</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balanceWeekend}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>7 dní</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balanceWeek}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>30 dní</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balanceMonth}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>90 dní</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balance3Months}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>180 dní</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balance6Months}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>365 dní</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balanceYear}
				</Typography>
			</div>

			<div>
				<Typography component='h2' variant='h5' color='#444444' gutterTop>
					<b>YTD</b>
				</Typography>
				<Typography component='p' variant='h4'>
					{balance.balanceYTD}
				</Typography>
			</div>
		</>
	);
}
