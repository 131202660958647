import axios from 'axios';
import Cookies from 'js-cookie';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#FFC005', // Change the background color here
	color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const defaultTheme = createTheme();

const CustomersPage = () => {
	const [open, setOpen] = React.useState(true);
	const [customers, setCustomers] = useState([]);
	const [originalRows, setOriginalRows] = useState([])
	const [rows, setRows] = useState([]);
	  

  const requestSearch = (searchedVal) => {
    const filteredRows = originalRows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.target.value.toLowerCase()) || row.phone.toLowerCase().includes(searchedVal.target.value.toLowerCase());
    });
    setRows(filteredRows);
  };

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const fetchCustomers = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/user/${Cookies.get('token')}`
		);
		return response.data;
	};

	const addToBlacklist = async (tel) => {
		//TODO: Add reason text
		axios.post(
			`${process.env.REACT_APP_API_URL}/api/user/blacklistPhone`,
			{ phone: tel, reasonText: 'Bez důvodu' }
		).then(() => {
			fetchCustomers().then((res) => {
				setCustomers(res);
			});
		})
		
	};

	const removeFromBlacklist = async (tel) => {
		//TODO: Add reason text
		axios.post(
			`${process.env.REACT_APP_API_URL}/api/user/blacklist/remove`,
			{phone: tel, reasonText: 'Bez důvodu'}
		).then(() => {
			fetchCustomers().then((res) => {
				setCustomers(res);
			});
		})
	};

	const removeCustomer = async (id) => {
		let prompt = window.prompt("Opravdu chcete smaza zákazníka? (ano/ne)", "ne", "ano");

		if (prompt === "ano") {
			axios.post(
				`${process.env.REACT_APP_API_URL}/api/user/delete`,
				{id_user: id}
			).then(() => {
				fetchCustomers().then((res) => {
					setCustomers(res);
				});
			})
		}
	}

	useEffect(() => {
		fetchCustomers().then((res) => {
			setCustomers(res);
		});
	}, []);

	useEffect(() => {
		let tempRows = [];

		customers.forEach((customer) => {
				
				tempRows.push({
					id: customer.id,
					name: `${customer.first_name || 'Zákazník'} ${customer.last_name || customer.phone.substring(5, 9)}`,
					phone: customer.phone,
					email: customer.email,
					status: customer.status,
					rides: customer.rides,
				})
		})

		setRows(tempRows);
		setOriginalRows(tempRows)
	}, [customers])

	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position='absolute' open={open}>
						<Toolbar
							sx={{
								pr: '24px', // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='open drawer'
								onClick={toggleDrawer}
								sx={{
									marginRight: '36px',
									...(open && { display: 'none' }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								component='h1'
								variant='h6'
								color='inherit'
								noWrap
								sx={{ flexGrow: 1 }}
							>
								Zákazníci
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer variant='permanent' open={open}>
						<Toolbar
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<List component='nav'>
							{mainListItems}
							<Divider sx={{ my: 1 }} />
							{secondaryListItems}
						</List>
					</Drawer>
					<Box
						component='main'
						sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar/>

                        <SosAlert/>

                        <Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
                            <h1>Zákazníci</h1>

                            <input
                                onChange={(searchVal) => requestSearch(searchVal)}
                                placeholder='Hledat'
                            />
                            <TableContainer>
                                <Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell>Jméno</TableCell>
										<TableCell align="right">Telefon</TableCell>
										<TableCell align="right">Email</TableCell>
										<TableCell align="right">Jízdy</TableCell>
										<TableCell align="right">Akce</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row, index) => (
										<TableRow key={`${row.name}-${index}`}
												  style={{backgroundColor: (row.status === "blacklist" ? "grey" : "white")}}>
											<TableCell>{row.id}</TableCell>
											<TableCell component="th" scope="row">
												{row.name}
											</TableCell>
											<TableCell align="right">{row.phone}</TableCell>
											<TableCell align="right">{row.email}</TableCell>
											<TableCell align="right">{row.rides}</TableCell>
											<TableCell align='right'>
												<div>{row.status !== "blacklist" ? <button
														onClick={() => addToBlacklist(row.phone)}
														style={{
															backgroundColor: '#333',
															color: '#fff',
															padding: '10px 20px',
															border: 'none',
															borderRadius: '4px',
															cursor: 'pointer',
															fontSize: '16px',
															fontWeight: 'bold',
													}}
												>
													Přidat na blacklist
												</button> :
												<button
												onClick={() => removeFromBlacklist(row.phone)}
												style={{
													backgroundColor: '#333',
													color: '#fff',
													padding: '10px 20px',
													border: 'none',
													borderRadius: '4px',
													cursor: 'pointer',
													fontSize: '16px',
													fontWeight: 'bold',
												}}
												>
													Odebrat z blacklistu
												</button>}
													<button onClick={() => removeCustomer(row.id)}
															style={{
																backgroundColor: '#333',
																color: '#fff',
																padding: '10px 20px',
																border: 'none',
																borderRadius: '4px',
																cursor: 'pointer',
																fontSize: '16px',
																fontWeight: 'bold',
															}}>
														&times;
													</button>
												</div>
											</TableCell>
									</TableRow>
								))}
								</TableBody>
							</Table>
							</TableContainer>

                            <Copyright sx={{ pt: 4 }} />
						</Container>
					</Box>
				</Box>
			</ThemeProvider>
		</>
	);
};

export default CustomersPage;
