import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#FFC005', // Change the background color here
	color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const defaultTheme = createTheme();

const NotificationPage = () => {
	const [open, setOpen] = React.useState(true);
	const [notifications, setNotifications] = useState([]);
	const content = useRef();
	const title = useRef();

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const createNotification = (e) => {
		e.preventDefault();

		axios
			.post(`${process.env.REACT_APP_API_URL}/api/notifications/create`, {
				content: content.current.value,
				title: title.current.value,
			})
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchNotifications = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/notifications/get`
		);
		return response.data;
	};

	function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${day}.${month} v ${hours}:${minutes}`;
	}

	useEffect(() => {
		fetchNotifications().then((res) => {
			setNotifications(res);
		});
	}, []);
	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position='absolute' open={open}>
						<Toolbar
							sx={{
								pr: '24px', // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='open drawer'
								onClick={toggleDrawer}
								sx={{
									marginRight: '36px',
									...(open && { display: 'none' }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								component='h1'
								variant='h6'
								color='inherit'
								noWrap
								sx={{ flexGrow: 1 }}
							>
								Oznámení
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer variant='permanent' open={open}>
						<Toolbar
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<List component='nav'>
							{mainListItems}
							<Divider sx={{ my: 1 }} />
							{secondaryListItems}
						</List>
					</Drawer>
					<Box
						component='main'
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === 'light'
									? theme.palette.grey[100]
									: theme.palette.grey[900],
							flexGrow: 1,
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Toolbar/>

						<SosAlert/>

						<Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
							<Grid container spacing={3}>
								{/* Chart */}

								{/* Recent Orders */}
								<Grid item xs={12}>
									<Paper
										sx={{p: 2, display: 'flex', flexDirection: 'column'}}
									>
										<div className='notification-page'>
											<h1 className='notification-page__title'>Oznámení</h1>
											<form
												className='notification-form'
												onSubmit={createNotification}
											>
												<label
													htmlFor='content'
													className='notification-form__label'
												></label>
												<input
													type='text'
													id='title'
													name='title'
													ref={title}
													maxLength={20}
													placeholder='Zadejte nadpis'
													className='notification-form__input'
												/>
												<textarea
													type='text'
													id='content'
													name='content'
													ref={content}
													maxLength={255}
													rows={4}
													placeholder='Zadejte text'
													className='notification-textarea'
												/>
												<input
													type='submit'
													value='Odeslat'
													className='notification-form__submit'
												/>
											</form>
											<hr className='notification-page__divider' />
											<div className='notification-list'>
												{notifications.reverse().map((notification) => (
													<div key={notification.id} className='notification'>
														<p className='date'>
															{formatDate(notification.createdAt)}
														</p>
														<p>{notification.title}</p>
														<p className='notification__content'>
															{notification.content}
														</p>
													</div>
												))}
											</div>
										</div>
									</Paper>
								</Grid>
							</Grid>
							<Copyright sx={{ pt: 4 }} />
						</Container>
					</Box>
				</Box>
			</ThemeProvider>
		</>
	);
};

export default NotificationPage;
