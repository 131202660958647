import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Modal, Box } from '@mui/material';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Alert } from '@mui/material';
import { useRef } from 'react';
import axios from 'axios';

const HomePage = () => {
	const name = useRef();
	const email = useRef();
	const subject = useRef();
	const message = useRef();

	const [alert, setAlert] = useState();

	// state variable to decide if we should render pop-up
	const [displayPopUp, setDisplayPopUp] = useState(true);

	// when pop-up is closed this function triggers, we pass it to 'onClose' property of the modal
	const closePopUp = () => {
		// setting key "seenPopUp" with value true into localStorage
		localStorage.setItem('seenPopUp', true);
		// setting state to false to not display pop-up
		setDisplayPopUp(false);
	};

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		textAlign: 'center',
		p: 4,
		outline: 0, // Remove default outline
		borderRadius: '8px', // Add rounded corners
	};

	const sendEmail = (e) => {
		e.preventDefault();
		console.log(`${process.env.REACT_APP_API_URL}/api/admins/sendWebEmail`);

		axios
			.post(`${process.env.REACT_APP_API_URL}/api/admins/sendWebEmail`, {
				name: name.current.value,
				email: email.current.value,
				subject: subject.current.value,
				message: message.current.value,
			})
			.then((res) => {
				setAlert(['success', 'Email byl úspěšně odeslán. Děkujeme!']);
			})
			.catch((err) => {
				console.log(err);
				setAlert([
					'error',
					'Email se nepodařilo odeslat. Zkuste to prosím později.',
				]);
			});
	};

	useEffect(() => {
		AOS.init({
			duration: 1000, // Animation duration in milliseconds (default is 1000)
			delay: 200, // Animation delay in milliseconds (default is 0)
		}); // Initialize AOS
	}, []);

	return (
		<>
			<Navbar />
			<div>
				{/* conditional rendering, if displayPopUp is truthy we will show the modal */}
				{displayPopUp && (
					<Modal
						open={true}
						onClose={closePopUp}
						aria-labelledby='modal-modal-title'
						aria-describedby='modal-modal-description'
					>
						<Box sx={modalStyle}>
							<img src='/img/lama-plakat.webp' className='img-fluid' />
							<button className='popup-button' onClick={closePopUp}>
								Zavřít
							</button>
						</Box>
					</Modal>
				)}
			</div>
			{/* this is the main content of this page */}
			<div>
				<h1>The main content</h1>
			</div>
			<section id='hero' className='hero'>
				<div className='container position-relative mt-lg-5'>
					<div
						className='row gy-5 '
						data-aos='fade-in'
						style={{ marginTop: '20px' }}
					>
						<div className='col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start'>
							<div className='wrapper'>
								<h2>
									<span>Lama Taxi s.r.o.</span>
								</h2>
								<p style={{ color: 'rgb(221, 221, 221)', textAlign: 'center' }}>
									Rozvoz po{' '}
									<span style={{ color: 'white' }}>
										<b>Lovosicích</b>
									</span>{' '}
									za
									<b>
										<span className='yellow'> 50Kč </span>
									</b>
									jen při objednání přes <b>aplikaci</b>!
									<br />
									Odvezeme až
									<span className='yellow'>
										<b> 8 osob</b>
									</span>{' '}
									!
								</p>
								<h2>
									<a
										href='tel:+420606043400'
										style={{ textDecoration: 'none' }}
									>
										<span className='yellow'>
											<b>606 043 400</b>
										</span>
									</a>
								</h2>
								<div className='d-flex justify-content-center justify-content-lg-start'>
									<a href='/' className='btn-get-started no-underline'>
										Aplikace je nedostupná
									</a>
								</div>

								{/* <div className='d-flex justify-content-center gap-3 align-items-center mt-4'>
									<div>
										<a href='https://play.google.com/store/apps/details?id=com.crudex.lamataxi'>
											<img
												src='/img/download/google-play-badge.png'
												alt=''
												className='img-fluid'
												style={{ height: '50px' }}
											/>
										</a>
									</div>
									<div>
										<a href='https://apps.apple.com/cz/app/lamataxi/id6453699118'>
											<img
												src='/img/download/black.svg'
												alt=''
												className='img-fluid'
												style={{ height: '50px' }}
											/>
										</a>
									</div>
								</div> */}
							</div>
						</div>
						<div className='col-lg-6 order-1 order-lg-2 d-flex justify-content-center align-self-center'>
							<img
								src='/img/logo/lama-logo.png'
								alt='lamataxi logo'
								className='img-fluid'
							/>
						</div>
					</div>

					<Alert
						variant={'filled'}
						severity='warning'
						style={{ width: 'fit-content' }}
					>
						Aplikace LamaTaxi se předělává, těšíme se na vás v novém roce.
					</Alert>
				</div>

				<div className='icon-boxes position-relative'>
					<div className='container position-relative'>
						<div className='row gy-4 mt-5 '>
							<div
								className='col-xl-4 col-md-6'
								data-aos='fade-up'
								data-aos-delay='100'
							>
								<div className='icon-box'>
									<div className='icon'>
										<img src='/img/icons/best-price.png' alt='icon' />
									</div>
									<h4 className='title'>
										<a href='' className='stretched-link no-underline'>
											Cena
										</a>
									</h4>
									<p>Odvezeme Vás za levnou cenu</p>
								</div>
							</div>

							<div
								className='col-xl-4 col-md-6'
								data-aos='fade-up'
								data-aos-delay='200'
							>
								<div className='icon-box'>
									<div className='icon'>
										<img src='/img/icons/convenient.png' alt='icon' />
									</div>
									<h4 className='title'>
										<a href='' className='stretched-link no-underline'>
											Komfort
										</a>
									</h4>
									<p>U nás budete vždy v pohodlí </p>
								</div>
							</div>

							<div
								className='col-xl-4 col-md-6'
								data-aos='fade-up'
								data-aos-delay='500'
							>
								<div className='icon-box'>
									<div className='icon'>
										<img src='/img/icons/driver.png' alt='Icon' />
									</div>
									<h4 className='title'>
										<a href='' className='stretched-link no-underline'>
											Profesionální řidiči
										</a>
									</h4>
									<p>U nás se bát nemusíte</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<main id='main'>
				<section id='pricing' className='pricing ' data-aos='fade-up'>
					<div className='container'>
						<div className='section-header'>
							<h2>Ceník taxi služby</h2>
							<p>Ceny naší taxi služby jsou dostupné v našich vozech.</p>
						</div>
					</div>
				</section>

				<a
					style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
					href='https://www.trustpilot.com/review/lamataxi.cz'
					target='_blank'
					rel='noopener'
				>
					<img
						src='/img/trustpilot.png'
						alt='trustpilot'
						className='img-fluid trustpilot'
					/>
				</a>

				<section id='about' className='about sections-bg'>
					<div className='container' data-aos='fade-up'>
						<div className='section-header'>
							<h2>O nás</h2>
						</div>

						<div className='row gy-4'>
							<div className='col-lg-6'>
								<img
									src='/img/about/delivery.svg'
									className='img-fluid rounded-4 mb-4'
									alt=''
								/>
							</div>

							<div className='col-lg-6'>
								<div className='content ps-0 ps-lg-5'>
									<ul>
										<li className='d-flex align-items-start'>
											<CheckCircleIcon />
											Lama Taxi je profesionální taxislužba poskytující přepravu
											po Lovosicích a okolí. Snažíme se nabídnout kvalitní a
											pohodlnou přepravu za dostupnou cenu. Naši zkušení řidiči
											se postarají o to, abyste se u nás cítili v bezpečí a
											pohodlí.
										</li>
										<li className='d-flex align-items-start'>
											<CheckCircleIcon />
											Nabízíme rozvoz po Lovosicích za 50 Kč. Naše ceny jsou
											příznivé a férové.
										</li>
										<li className='d-flex align-items-start'>
											<CheckCircleIcon />
											Pokud chcete využít našich služeb, můžete si objednat taxi
											online nebo si stáhnout naši aplikaci. S naší aplikací
											můžete snadno a rychle objednat taxi a sledovat příjezd
											vašeho řidiče.
										</li>
										<li className='d-flex align-items-start'>
											<CheckCircleIcon />
											Jsme hrdí na naše profesionální řidiče, kteří jsou zkušení
											a spolehliví. Vaše bezpečí a spokojenost jsou pro nás
											prioritou.
										</li>
									</ul>

									<p style={{ marginLeft: '30px' }}>
										Pro více informací nás neváhejte kontaktovat. Můžete nám
										poslat e-mail na info@lamataxi.cz nebo nám zavolat na
										telefonní číslo +420 606 043 400
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id='services' className='services '>
					<div className='container' data-aos='fade-up'>
						<div className='section-header'>
							<h2>Aplikace</h2>
						</div>

						<div className='row gy-4'>
							<div className='col-lg-6 d-flex align-items-center justify-content-center'>
								<img
									src='/img/mobile.svg'
									className='img-fluid rounded-4 mb-4'
									alt=''
								/>
							</div>
							<div className='col-lg-6'>
								<div className='content ps-0 ps-lg-5'>
									<p>
										Naše aplikace Lama Taxi je speciálně navržena pro Váš
										maximální komfort a pohodlí při využívání taxi služeb.
										Objednání taxíku přímo z vašeho mobilního zařízení je teď
										snadné a rychlé. Stačí zadat aktuální polohu a cílovou
										destinaci a náš spolehlivý taxi Vás okamžitě doručí.
										Využijte řadu výhod, které naše aplikace nabízí:
									</p>

									<ol>
										<li>
											Snadné objednávání: S intuitivním rozhraním aplikace
											zvládnete objednat taxi jen pár kliknutími.
										</li>
										<li>
											Transparentní cenová kalkulace: Zjistěte odhadovanou cenu
											jízdy předem, abyste věděli, kolik budete platit, a
											vyhnuli se nepříjemným překvapením.
										</li>
										<li>
											Pohodlné platební možnosti: Máme pro Vás různé platební
											možnosti, včetně hotovostní, plateb kreditem nebo
											bezkontaktní platby.
										</li>
										<li>
											Sledování jízdy v reálném čase: Na mapě ve své aplikaci
											sledujte pohyb taxi a připravte se na jeho příjezd.
										</li>
									</ol>

									<p>
										Stáhněte si naši aplikaci Lama Taxi ještě dnes a využijte
										všechny tyto výhody během svých cest. Aplikaci můžete snadno
										stáhnout z Google Play pro zařízení s operačním systémem
										Android nebo z App Store pro zařízení s operačním systémem
										iOS.
									</p>

									{/* <div className='container'>
										<div className='row'>
											<div className='col-6'>
												<a href='https://play.google.com/store/apps/details?id=com.crudex.lamataxi'>
													<img
														src='/img/download/google-play-badge.png'
														alt=''
														className='img-fluid w-100 h-100'
													/>
												</a>
											</div>
											<div className='col-6'>
												<a href='https://apps.apple.com/cz/app/lamataxi/id6453699118'>
													<img
														src='/img/download/black.svg'
														alt=''
														className='img-fluid w-100 h-100'
													/>
												</a>
											</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id='contact' className='contact sections-bg'>
					<div className='container' data-aos='fade-up'>
						<div className='section-header'>
							<h2>Kontakt</h2>
						</div>

						<div className='row gx-lg-0 gy-4'>
							<div className='col-lg-4'>
								<div className='info-container d-flex flex-column align-items-center justify-content-center'>
									<div className='info-item d-flex'>
										<i className='bi  flex-shrink-0'>
											<HomeIcon />
										</i>
										<div>
											<h4>Adresa:</h4>
											<p>Lovosice</p>
										</div>
									</div>

									<div className='info-item d-flex'>
										<i className='bi  flex-shrink-0'>
											<EmailIcon />
										</i>
										<div>
											<h4>Email:</h4>
											<p>info@lamataxi.cz</p>
										</div>
									</div>

									<div className='info-item d-flex'>
										<i className='bi bi-phone flex-shrink-0'>
											<SmartphoneIcon />
										</i>
										<div>
											<h4>Telefon:</h4>
											<p>
												{' '}
												<a
													href='tel:+420606043400'
													className='no-underline text-light'
												>
													+420 606 043 400
												</a>
											</p>
										</div>
									</div>

									<div className='info-item d-flex'>
										<i className='bi bi-clock flex-shrink-0'>
											<AccessTimeIcon />
										</i>
										<div>
											<h4>Otevírací doba:</h4>
											<p>Po-Čt: 7:00 - 22:00</p>
											<p>Pá: 7:00 - So: 5:00</p>
											<p>So: 20:00 - Ne: 5:00</p>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-8'>
								<form method='post' role='form' className='php-email-form'>
									<div className='row'>
										<div className='col-md-6 form-group'>
											<input
												ref={name}
												type='text'
												name='name'
												className='form-control'
												id='name'
												placeholder='Vaše jméno'
												required
											/>
										</div>
										<div className='col-md-6 form-group mt-3 mt-md-0'>
											<input
												ref={email}
												type='email'
												className='form-control'
												name='email'
												id='email'
												placeholder='Váš email'
												required
											/>
										</div>
									</div>
									<div className='form-group mt-3'>
										<input
											ref={subject}
											type='text'
											className='form-control'
											name='subject'
											id='subject'
											placeholder='Předmět'
											required
										/>
									</div>
									<div className='form-group mt-3'>
										<textarea
											ref={message}
											className='form-control'
											name='message'
											rows='7'
											placeholder='Zpráva'
											required
										></textarea>
									</div>
									<div className='my-3'>
										<div className='loading'>Načítání</div>
										<div className='error-message'></div>
										<div className='sent-message'>
											Vaše zpráva byla odeslána. Děkujeme!
										</div>
									</div>
									<div className='text-center'>
										<button type='submit' onClick={sendEmail}>
											Odeslat
										</button>
									</div>

									{alert && (
										<Alert
											severity={alert[0]}
											style={{ width: 'fit-content', marginTop: '20px' }}
										>
											{alert[1]}
										</Alert>
									)}
								</form>
							</div>
						</div>
					</div>
				</section>
			</main>

			<Footer />

			<a
				href='#'
				className='scroll-top d-flex align-items-center justify-content-center no-underline'
			>
				<i className='bi bi-arrow-up-short'></i>
			</a>
		</>
	);
};

export default HomePage;
