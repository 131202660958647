import axios from 'axios';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@mui/material';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#FFC005', // Change the background color here
	color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const defaultTheme = createTheme();

const VipPage = () => {
	const tel = useRef();
	const discount = useRef();
	const [open, setOpen] = React.useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	const [code, setCode] = useState(generateRandomString());
	const [vips, setVips] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	const [rows, setRows] = useState([]);

	const requestSearch = (searchedVal) => {
		const filteredRows = originalRows.filter((row) => {
			return (
				row.code
					.toLowerCase()
					.includes(searchedVal.target.value.toLowerCase()) ||
				row.phone.toLowerCase().includes(searchedVal.target.value.toLowerCase())
			);
		});
		setRows(filteredRows);
	};

	function generateRandomString() {
		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		let randomString = '';

		for (let i = 0; i < 8; i++) {
			let randomIndex = Math.floor(Math.random() * chars.length);
			randomString += chars.charAt(randomIndex);
		}

		return randomString;
	}

	const generateCode = (e) => {
		e.preventDefault();

		axios
			.post(`${process.env.REACT_APP_API_URL}/api/vip/create`, {
				phone: tel.current.value,
				discount: discount.current.value,
				code: code,
			})
			.then((res) => {
				fetchVip();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchVip = () => {
		axios.get(`${process.env.REACT_APP_API_URL}/api/vip`).then((res) => {
			setVips(res.data);
		});
	};

	useEffect(() => {
		fetchVip();
	}, []);

	useEffect(() => {
		let tempRows = [];
		vips.forEach((vip) => {
			tempRows.push({
				id: vip.id,
				phone: vip.phone,
				code: vip.code,
				discount: vip.discount,
			});
		});

		setRows(tempRows);
		setOriginalRows(tempRows);
	}, [vips]);

	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position='absolute' open={open}>
						<Toolbar
							sx={{
								pr: '24px', // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='open drawer'
								onClick={toggleDrawer}
								sx={{
									marginRight: '36px',
									...(open && { display: 'none' }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								component='h1'
								variant='h6'
								color='inherit'
								noWrap
								sx={{ flexGrow: 1 }}
							>
								VIP
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer variant='permanent' open={open}>
						<Toolbar
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<List component='nav'>
							{mainListItems}
							<Divider sx={{ my: 1 }} />
							{secondaryListItems}
						</List>
					</Drawer>
					<Box
						component='main'
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === 'light'
									? theme.palette.grey[100]
									: theme.palette.grey[900],
							flexGrow: 1,
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Toolbar/>

						<SosAlert/>

						<Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
							<Grid container spacing={3}>
								{/* Recent Orders */}
								<Grid item xs={12}>
									<Paper
										sx={{p: 2, display: 'flex', flexDirection: 'column'}}
									>
										<form onSubmit={generateCode} className='vip-container'>
											<div className='vip-item'>
												<label htmlFor='tel'>Telefonní číslo</label>
												<input
													type='text'
													id='tel'
													name='tel'
													ref={tel}
													onChange={() => setCode(generateRandomString())}
													placeholder='Telefonní číslo'
													className='vip-input'
												/>
											</div>
											<div className='vip-item'>
												<label htmlFor='discount'>Sleva</label>
												<input
													type='number'
													id='discount'
													name='discount'
													ref={discount}
													placeholder='Sleva'
													className='vip-input'
												/>
											</div>
											<div className='vip-item'>
												<label htmlFor='code'>VIP Kód</label>
												<input
													type='text'
													id='code'
													name='code'
													value={code}
													className='vip-input'
												/>
											</div>

											<input
												type='submit'
												value='Vytvořit'
												className='vip-button'
											/>
										</form>
									</Paper>
								</Grid>
							</Grid>

							<h1>VIP</h1>

							<input
								onChange={(searchVal) => requestSearch(searchVal)}
								placeholder='Hledat'
							/>
							<TableContainer>
								<Table aria-label='simple table'>
									<TableHead>
										<TableRow>
											<TableCell>Telefon</TableCell>
											<TableCell align='right'>Kód</TableCell>
											<TableCell align='right'>Sleva</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row, index) => (
											<TableRow key={`${row.phone}-${index}`}>
												<TableCell component='th' scope='row'>
													{row.phone}
												</TableCell>
												<TableCell align='right'>{row.code}</TableCell>
												<TableCell align='right'>{row.discount}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>

							<Copyright sx={{ pt: 4 }} />
						</Container>
					</Box>
				</Box>
			</ThemeProvider>
		</>
	);
};

export default VipPage;
