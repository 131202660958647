import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const PrivateRoute = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const checkUser = async () => {
        if (!Cookies.get('token')) {
            window.location.href = '/login';
        }

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/admins/${Cookies.get('token')}`);
            if (res.data === false) {
                window.location.href = '/login';
            } else {
                setLoading(false);
            }
        } catch (err) {
            window.location.href = '/login';
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await checkUser();
        };

        fetchData();
    }, []);

    if (loading) {
        console.log('Loading...');
    } else {
        return children;
    }
};

export default PrivateRoute;
