import React, {useEffect, useState} from 'react';
import {compose, withProps} from 'recompose';
import {GoogleMap, InfoWindow, withGoogleMap, withScriptjs,} from 'react-google-maps';
import {MarkerWithLabel} from 'react-google-maps/lib/components/addons/MarkerWithLabel';

const labelOffset = {x: 0, y: 32}; // Adjust the x and y values to control the label offset

const icon = {
	url: '/img/icons/taxi3.png',
	labelOrigin: {x: 16, y: 16}, // Adjust these values based on the icon size
	labelAnchor: {x: labelOffset.x, y: labelOffset.y},
};

const TestMap = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `95%`, width: '100%' }} />,
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)((props) => {
	const [google, setGoogle] = useState(null);
	const [hoveredMarker, setHoveredMarker] = useState(null);

	useEffect(() => {
		// Load Google Maps script asynchronously
		const loadGoogleScript = () => {
			const script = document.createElement('script');
			script.src = props.googleMapURL;
			script.onload = () => setGoogle(window.google);
			document.body.appendChild(script);
		};

		// Check if the google object is already available (e.g., script was loaded from cache)
		if (window.google) {
			setGoogle(window.google);
		} else {
			// Load the Google Maps script
			loadGoogleScript();
		}
	}, [props.googleMapURL]);

	// Check if the 'google' object is available before proceeding
	if (!google) return null;

	const handleMarkerHover = (marker) => {
		setHoveredMarker(marker);
	};

	const handleMarkerMouseOut = () => {
		setHoveredMarker(null);
	};

	return (
		<GoogleMap defaultZoom={13} defaultCenter={{ lat: 50.51504, lng: 14.05103 }}>
			{props.drivers?.map((driver) => (
				<MarkerWithLabel
					key={driver.carID}
					position={{
						lat: parseFloat(driver.position.latitude),
						lng: parseFloat(driver.position.longitude),
					}}
					icon={icon}
					labelAnchor={new google.maps.Point(0, 0)}
					labelStyle={{
						backgroundColor: 'grey',
						fontSize: '16px',
						padding: '4px',
					}}
				>
					<div>
						{driver.toolTipText} - {driver.spz}
					</div>
				</MarkerWithLabel>
			))}

			{props.orders?.map((order) => (
				<MarkerWithLabel
					key={order.id}
					position={{
						lat: parseFloat(JSON.parse(order.pickup_location).lat),
						lng: parseFloat(JSON.parse(order.pickup_location).lng),
					}}
					onMouseOver={() => handleMarkerHover(order)}
					onMouseOut={handleMarkerMouseOut}
					labelAnchor={new google.maps.Point(0, 0)}
					labelStyle={{
						backgroundColor: 'grey',
						fontSize: '16px',
						padding: '4px',
					}}
				>
					<div>{order.price} Kč</div>
				</MarkerWithLabel>

			))}

			{hoveredMarker && (
				<InfoWindow
					position={{
						lat: parseFloat(JSON.parse(hoveredMarker.pickup_location).lat) + 0.003,
						lng: parseFloat(JSON.parse(hoveredMarker.pickup_location).lng) + 0.003,
					}}
					onCloseClick={handleMarkerMouseOut}
				>
					<div>
						Start: {JSON.parse(hoveredMarker.pickup_location).name} <br/>
						Cíl: {JSON.parse(hoveredMarker.destination).name} <br/>
						Vzdálenost: {hoveredMarker.distance} km <br/>
						Sazba: {hoveredMarker.price} Kč<br/>
						Status: {hoveredMarker.status === "waiting" ? "čeká" : hoveredMarker.status === "accepted" ? "Přijato" : "error"} <br/>
						Osoby: {hoveredMarker.people}
					</div>
				</InfoWindow>
			)}
		</GoogleMap>
	);
});

export default TestMap;
