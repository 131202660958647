import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const GalleryPage = () => {
	return (
		<>
			<Navbar />

			<section id='gallery'>
				<h1>
					<b>Galerie</b>
				</h1>

				<Box sx={{ flexGrow: 1, p: 2 }}>
					<Grid container spacing={2} className='gallery-div'>
						{Array.from({ length: 19 }).map((_, index) => (
							<Grid item xs={12} sm={6} md={4} key={index}>
								<img
									src={`/img/gallery/${index + 1}.webp`}
									loading='lazy'
									alt={`gallery${index + 1}`}
									style={{ width: '100%', height: 'auto', display: 'block' }}
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			</section>
			<Footer />
		</>
	);
};

export default GalleryPage;
