import React, {useRef, useState} from 'react';
import {Alert, Collapse} from '@mui/material';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import Cookies from 'js-cookie';

const LoginPage = () => {
	const [alert, setAlert] = useState('');

	const user = useRef();
	const pass = useRef();

	const handleLogin = (e) => {
		e.preventDefault();

		axios
			.post(`${process.env.REACT_APP_API_URL}/api/admins/login`, {
				username: user.current.value,
				password: pass.current.value,
			})
			.then((res) => {
				if (res.data.accessToken) {
					Cookies.set('token', res.data.accessToken);
					window.location.href = '/admin/dashboard';
				} else {
					setAlert('Špatné přihlašovací údaje');
				}
			})
			.catch(() => {
				setAlert('Špatné přihlašovací údaje');
			});
	};

	return (
		<>
			<Navbar />
			<section id='login' className='login-section'>
				<div className='login-form'>
					<h2>
						<b>Přihlaste se</b>
					</h2>
					<form onSubmit={handleLogin}>
						<Collapse style={{ marginBottom: '5px' }} in={alert !== ''}>
							<Alert severity={'error'}>{alert}</Alert>
						</Collapse>
						<div className='form-group'>
							<label htmlFor='username'>Jméno</label>
							<input type='text' id='username' required ref={user} />
						</div>
						<div className='form-group'>
							<label htmlFor='password'>Heslo</label>
							<input type='password' id='password' required ref={pass} />
						</div>
						<button type='submit'>Přihlásit se</button>
					</form>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default LoginPage;
