import React from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Alert } from '@mui/material';

const Footer = () => {
	return (
		<>
			<footer id='footer' className='footer'>
				<div className='container'>
					<div className='row gy-4 justify-content-evenly'>
						<div className='col-lg-3 col-md-6 footer-info'>
							<a
								href='/'
								className='logo d-flex align-items-center no-underline'
							>
								<span>Lama Taxi s.r.o.</span>
							</a>
							<p>
								Lama Taxi je inovativní taxi služba, která vám poskytuje
								spolehlivý a pohodlný způsob dopravy. S naší aplikací můžete
								snadno objednat taxi a mít jistotu rychlého příjezdu.{' '}
							</p>
							<a href='/privacy-policy'>Zásady ochrany osobních údajů</a>
							<br />
							<a href='/terms-and-conditions'>Podmínky užívání</a>

							<div className='social-links d-flex mt-4'>
								<a
									href='https://www.facebook.com/profile.php?id=100095147918807'
									className='facebook no-underline'
								>
									<FacebookRoundedIcon />
								</a>
								<a
									href='https://www.instagram.com/lama_taxi/'
									className='instagram no-underline'
								>
									<InstagramIcon />
								</a>
							</div>
						</div>
						<div className='col-lg-6 col-md-12 text-center'>
							<a
								href='/'
								className='logo d-flex align-items-center justify-content-center no-underline'
							>
								<img
									src='/img/logo/lama-logo.png'
									alt='Logo'
									width='180'
									height='180'
								/>
							</a>
						</div>
						<div className='col-lg-3 col-md-6'>
							{/* <div className='container'>
								<div className='row'>
									<div className='col-12'>
										<a href='https://play.google.com/store/apps/details?id=com.crudex.lamataxi'>
											<img
												src='/img/download/google-play-badge.png'
												alt=''
												className='img-fluid w-100 h-100'
											/>
										</a>
									</div>
									<div className='col-12'>
										<a href='https://apps.apple.com/cz/app/lamataxi/id6453699118'>
											<img
												src='/img/download/black.svg'
												alt=''
												className='img-fluid w-100 h-100'
											/>
										</a>
									</div>
								</div> */}
							<Alert
								variant={'filled'}
								severity='warning'
								style={{ width: 'fit-content' }}
							>
								Aplikace LamaTaxi se předělává, těšíme se na vás v novém roce.
							</Alert>
						</div>
					</div>
				</div>
				<div className='container mt-4'>
					<div className='row'>
						<div className='col text-center'>
							<p>
								&copy; 2023 lamataxi.cz. Všechna práva vyhrazena. Vytvořil{' '}
								<a
									href='https://crudex.cz'
									style={{ color: '#FAD02C' }}
									className='no-underline'
								>
									Crudex
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
