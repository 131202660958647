import axios from 'axios';
import Cookies from 'js-cookie';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import {socket} from '../../components/socket';
import TestMap from '../../components/RealtimeMap';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#FFC005', // Change the background color here
	color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const defaultTheme = createTheme();



const MapPage = () => {
	const [open, setOpen] = React.useState(true);
    const [drivers, setDrivers] = useState(null);
    const [orders, setOrders] = useState(null);

	const toggleDrawer = () => {
		setOpen(!open);
	};

    const fetchDrivers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admins/${Cookies.get("token")}/drivers/`);
        return response.data;
    }

    const fetchOrders = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/order/getMapData`
		);
		return response.data;
	};

	
    useEffect(() => {
		// fetchDrivers().then((data) => {
		//     setDrivers(data);
		// });

		setInterval(() => {
			axios.post(`https://login.online-monitoring.cz:9888/api/Authorize/Token`, {
				username: "lama taxi",
				password: "Osvoboditelu941",
				workspaceId: 1
			}).then((res) => {
				axios.get(`https://login.online-monitoring.cz:9888/api/Car/GroupHierarchy`, {headers: {"Authorization": `Bearer ${res.data.accessToken}`}}).then((response) => {
					const allCars = [];

					response.data.carGroups.forEach(carGroup => {
						// Iterate through cars in each carGroup
						carGroup.cars.forEach(car => {
							allCars.push(car);
						});
					});

					setDrivers(allCars)
				})
			})
		}, 10000)

		fetchOrders().then((data) => {
			setOrders(data);
		});

		socket.connect()

		socket.on('connection', () => {
			console.log('WS: connected');
		});


		socket.on("driverLocationChange", () => {
			// fetchDrivers().then((data) => {
			//     setDrivers(data);
			// });


			fetchOrders().then((data) => {
				setOrders(data);
			});
		})
    }, []);

    const labelOffset = { x: 0, y: 32 }; // Adjust the x and y values to control the label offset

    const icon = {
      url: '/img/icons/taxi3.png',
      labelOrigin: { x: 16, y: 16 }, // Adjust these values based on the icon size
      labelAnchor: { x: labelOffset.x, y: labelOffset.y },
    };

	

	return (
		<>
			<ThemeProvider theme={defaultTheme}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position='absolute' open={open}>
						<Toolbar
							sx={{
								pr: '24px', // keep right padding when drawer closed
							}}
						>
							<IconButton
								edge='start'
								color='inherit'
								aria-label='open drawer'
								onClick={toggleDrawer}
								sx={{
									marginRight: '36px',
									...(open && { display: 'none' }),
								}}
							>
								<MenuIcon />
							</IconButton>
							<Typography
								component='h1'
								variant='h6'
								color='inherit'
								noWrap
								sx={{ flexGrow: 1 }}
							>
								Mapa
							</Typography>
						</Toolbar>
					</AppBar>
					<Drawer variant='permanent' open={open}>
						<Toolbar
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								px: [1],
							}}
						>
							<IconButton onClick={toggleDrawer}>
								<ChevronLeftIcon />
							</IconButton>
						</Toolbar>
						<Divider />
						<List component='nav'>
							{mainListItems}
							<Divider sx={{ my: 1 }} />
							{secondaryListItems}
						</List>
					</Drawer>
					<Box
						component='main'
						sx={{
							backgroundColor: (theme) =>
								theme.palette.mode === 'light'
									? theme.palette.grey[100]
									: theme.palette.grey[900],
							flexGrow: 1,
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Toolbar/>

						<SosAlert/>

						<TestMap isMarkerShown={true} drivers={drivers} orders={orders}/>
						<Copyright sx={{pt: 4}}/>
					</Box>
				</Box>
			</ThemeProvider>
		</>
	);
};

export default MapPage;
