import axios from 'axios';
import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {socket} from './socket';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function Orders() {
	const [orders, setOrders] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [refreshOrders, setRefreshOrders] = useState(false);

	const fetchOrders = async () => {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/order`
		);
		return response.data;
	};

	const statusTranslations = {
		waiting: 'čeká',
		accepted: 'přijmuto',
		done: 'hotovo',
		declined: 'odmítnuta',
		pickup: 'vyzvednuto',
	};

	const handleDeleteOrder = async (transId, orderId) => {
		// Display confirmation dialog
		const userConfirmed = window.confirm(
			'Jste si jistý že chcete smazat tuto objednávku?'
		);

		if (userConfirmed) {
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/api/order/delete`, {
					id: transId,
					orderId: orderId,
				});

				// Refresh the orders after deletion
				setRefreshOrders((prev) => !prev);
			} catch (error) {
				console.error('Error deleting order:', error);
			}
		}
	};

	useEffect(() => {
		fetchOrders()
			.then((data) => {
				setOrders(data);
			})
			.catch((e) => console.log('Orders: ' + e));
	}, []);

	useEffect(() => {
		fetchOrders()
			.then((data) => {
				setOrders(data);
			})
			.catch((e) => console.log('Orders: ' + e));
	}, [refreshOrders]);

	useEffect(() => {
		socket.connect();

		socket.on('connection', () => {
			console.log('WS: connected');
		});

		socket.on('ride', () => {
			setRefreshOrders(Math.random());
		});

		socket.on('rideAccept', () => {
			setRefreshOrders(Math.random());
		});

		socket.on('rideDecline', () => {
			setRefreshOrders(Math.random());
		});

		socket.on('rideComplete', () => {
			setRefreshOrders(Math.random());
		});

		socket.on('ridePickup', () => {
			setRefreshOrders(Math.random());
		});
	}, []);

	// Filter orders by date
	const filteredOrders = selectedDate
		? orders.filter((order) => {
				const orderDate = new Date(order.ride.createdAt);
				return (
					orderDate.getDate() === selectedDate.getDate() &&
					orderDate.getMonth() === selectedDate.getMonth() &&
					orderDate.getFullYear() === selectedDate.getFullYear()
				);
		  })
		: selectedMonth
		? orders.filter((order) => {
				const orderDate = new Date(order.ride.createdAt);
				return (
					orderDate.getMonth() === selectedMonth.getMonth() &&
					orderDate.getFullYear() === selectedMonth.getFullYear()
				);
		  })
		: orders;

	// Reset selected month
	const resetMonth = () => {
		setSelectedMonth(null);
	};

	// Display all orders
	const displayAllOrders = () => {
		setSelectedDate(null);
		setSelectedMonth(null);
	};

	return (
		<React.Fragment>
			<Typography component='h2' variant='h5' color='#444444' gutterBottom>
				<b>Objednávky</b>
			</Typography>

			<div>
				{/* Date selection input */}
				<input
					className='date-input'
					type='date'
					value={selectedDate ? selectedDate.toISOString().slice(0, 10) : ''}
					onChange={(e) => setSelectedDate(new Date(e.target.value))}
				/>

				{/* Month selection input */}
				<input
					className='month-input'
					type='month'
					value={
						selectedMonth
							? `${selectedMonth.getFullYear()}-${String(
									selectedMonth.getMonth() + 1
							  ).padStart(2, '0')}`
							: ''
					}
					onChange={(e) => setSelectedMonth(new Date(e.target.value))}
				/>

				{/* Display all orders button */}
				<button className='display-button' onClick={displayAllOrders}>
					Zobrazit všechny objednávky
				</button>
			</div>
			<div
				style={{ overflowY: 'auto', maxHeight: '600px' }}
			>
				<Table size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Datum</TableCell>
							<TableCell>Jméno zákazníka</TableCell>
							<TableCell>Odkud</TableCell>
							<TableCell>Kam</TableCell>
							<TableCell></TableCell>
							<TableCell>Délka</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Cena</TableCell>
							<TableCell>Platba</TableCell>
							<TableCell>Řidič</TableCell>
							<TableCell align='right'>Akce</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredOrders.reverse().map((order) => (

							<TableRow key={order.id}>
								<TableCell>
									{new Date(order.ride.createdAt).toLocaleString('cs-CZ', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
									})}
								</TableCell>
								<TableCell>
									{' '}
									{order?.user?.first_name || order?.order_user?.last_name}{' '}
									{order?.user?.last_name || ''}
								</TableCell>
								<TableCell>
									{JSON.parse(order.ride.pickup_location)
										.name.split(',')[0]
										.trim()}
								</TableCell>
								<TableCell>
									{JSON.parse(order.ride.destination).name.split(',')[0].trim()}
								</TableCell>
								<TableCell>{order.ride.isPreorder ? <div><span
									style={{color: "red"}}>Předobjednávka!</span> {new Date(order.ride.preorderDateTime).toLocaleString("cs")}
								</div> : null}</TableCell>
								<TableCell>{order.ride.distance} km</TableCell>
								<TableCell className={order.ride.status.toLowerCase()}>
									{' '}
									<b>{statusTranslations[order.ride.status.toLowerCase()]}</b>
								</TableCell>
								<TableCell>{`${order.ride.price} Kč`}</TableCell>
								<TableCell>{order.ride.payment_method === "card" ? "Kartou" : "Hotově"}</TableCell>
								<TableCell>{order.ride.driver?.user?.last_name || order.ride.driver_name || null}</TableCell>
                                <TableCell align='right'>
                                    <DeleteForeverIcon
										onClick={() => handleDeleteOrder(order.id, order.id_order)}
										style={{cursor: 'pointer'}}
                                    />
                                </TableCell>
							</TableRow>
						))}

						<TableRow>
							<TableCell><b>Celkem: </b></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>

							<TableCell
								align='right'><b>{filteredOrders.reduce((a, b) => a + b.ride.price, 0)} Kč</b></TableCell>
							<TableCell></TableCell>
						</TableRow>

					</TableBody>
				</Table>
			</div>
		</React.Fragment>
	);
}
