import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {mainListItems, secondaryListItems} from '../../components/listItems';
import Deposits from '../../components/Deposits';
import Orders from '../../components/OrdersLimited';
import axios from 'axios';
import Cookies from 'js-cookie';
import SosAlert from "../../components/SosAlert";
import Copyright from "../../components/Copyright";

const drawerWidth = 240;

const defaultWorkHours = [
  { from: "00:00", to: "23:59", day: "Pondělí" },
  { from: "00:00", to: "23:59", day: "Úterý" },
  { from: "00:00", to: "23:59", day: "Středa" },
  { from: "00:00", to: "23:59", day: "Čtvrtek" },
  { from: "00:00", to: "23:59", day: "Pátek" },
  { from: "00:00", to: "23:59", day: "Sobota" },
  { from: "00:00", to: "23:59", day: "Neděle" },
];


const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#FFC005', // Change the background color here
	color: '#444444',
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const defaultTheme = createTheme();

export default function DashboardPage() {
	const [open, setOpen] = React.useState(true);
	const [workHours, setWorkHours] = useState(null);
	const [day, setDay] = useState(1)
	const from = useRef()
	const to = useRef()

	const getDayById = (id) => {
		if (id === 1) {
			return "Pondělí"
		}
		if (id === 2) {
			return "Úterý"
		}
		if (id === 3) {
			return "Středa"
		}
		if (id === 4) {
			return "Čtvrtek"
		}
		if (id === 5) {
			return "Pátek"
		}
		if (id === 6) {
			return "Sobota"
		}
		if (id === 7) {
			return "Neděle"
		}
	}

	const deleteWorkHour = (wh) => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/admins/removeWorkHours`, {token: Cookies.get("token"), id: wh}).then((response) => {
			fetchWorkHours().then((data) => {
				setWorkHours(data);
			});
		})
	}

	const updateWorkHours = () => {

		axios.post(`${process.env.REACT_APP_API_URL}/api/admins/setWorkhours`, {token: Cookies.get("token"), id: day, from: from.current.value, to: to.current.value}).then((response) => {
			fetchWorkHours().then((data) => {
				setWorkHours(data);
			});
		})
	}

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const fetchWorkHours = async () => {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admins/${Cookies.get("token")}/workhours`);
		return response.data;
	}

	useEffect(() => {
		fetchWorkHours().then((data) => {
			setWorkHours(data);
		});

	}, []);

	return (
		<ThemeProvider theme={defaultTheme}>
			<Box sx={{ display: 'flex' }}>
				<CssBaseline />
				<AppBar position='absolute' open={open}>
					<Toolbar
						sx={{
							pr: '24px', // keep right padding when drawer closed
						}}
					>
						<IconButton
							edge='start'
							color='inherit'
							aria-label='open drawer'
							onClick={toggleDrawer}
							sx={{
								marginRight: '36px',
								...(open && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component='h1'
							variant='h6'
							color='inherit'
							noWrap
							sx={{ flexGrow: 1 }}
						>
							Admin panel
						</Typography>
					</Toolbar>
				</AppBar>
				<Drawer variant='permanent' open={open}>
					<Toolbar
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							px: [1],
						}}
					>
						<IconButton onClick={toggleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</Toolbar>
					<Divider />
					<List component='nav'>
						{mainListItems}
						<Divider sx={{ my: 1 }} />
						{secondaryListItems}
					</List>
				</Drawer>
				<Box
					component='main'
					sx={{
						backgroundColor: (theme) =>
							theme.palette.mode === 'light'
								? theme.palette.grey[100]
								: theme.palette.grey[900],
						flexGrow: 1,
						height: '100vh',
						overflow: 'auto',
					}}
				>
					<Toolbar/>

					<SosAlert/>

					<Container maxWidth='lg' sx={{mt: 4, mb: 4}}>
						<Grid container spacing={3}>
							{/* Recent Deposits */}
							<Grid item xs={12}>
								<Paper
									sx={{
										p: 2,
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										width: '100%',
									}}
								>
									<Deposits/>
								</Paper>
							</Grid>
							{/* Recent Orders */}
							<Grid item xs={12}>
								<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
									<Orders />
								</Paper>
							</Grid>

							<Grid item xs={12}>
								<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
									<div>
										{workHours &&
											workHours.map((workHour) => {
												return (
													<div>
														{workHour.from !== null ? new Date(workHour.from).toLocaleTimeString("cs", {
															hour: '2-digit',
															minute: '2-digit'
														}) : "00:00"} {workHour.to !== null ? new Date(workHour.to).toLocaleTimeString("cs", {
														hour: '2-digit',
														minute: '2-digit'
													}) : "00:00"} - {getDayById(parseInt(workHour.day))} <span
														style={{cursor: "pointer"}}
														onClick={() => deleteWorkHour(workHour.id)}>x</span>
													</div>
												);
											}
											)
										}
									</div>

									<div>
										<input type='time' ref={from}/>
										<input type='time' ref={to}/>
										<select onChange={(e) => setDay(e.target.value)}>
											<option value={1}>Pondělí</option>
											<option value={2}>Úterý</option>
											<option value={3}>Středa</option>
											<option value={4}>Čtvrtek</option>
											<option value={5}>Pátek</option>
											<option value={6}>Sobota</option>
											<option value={7}>Neděle</option>
										</select>
										<button onClick={updateWorkHours}>Uložit</button>
									</div>
								</Paper>

								
							</Grid>

							<Grid item xs={12}>
							<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
									<div>
										
									</div>
								</Paper>
							</Grid>
						</Grid>
						<Copyright sx={{ pt: 4 }} />
					</Container>
				</Box>
			</Box>
		</ThemeProvider>
	);
}
