import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
	const [showNavbar, setShowNavbar] = useState(false);

	const handleShowNavbar = () => {
		setShowNavbar(!showNavbar);
	};
	return (
		<>
			<header id='header' className='header d-flex align-items-center'>
				<div className='container-fluid container-xl d-flex align-items-center justify-content-between'>
					<a
						href='/'
						className='logo d-flex align-items-center no-underline'
						style={{ width: '100%' }}
					>
						<h1>Lama Taxi s.r.o.</h1>
					</a>
					<nav id='navbar' className='navbar stroke'>
						<div className='navbar-container'>
							<div className='menu-icon' onClick={handleShowNavbar}>
								<MenuIcon style={{ color: 'white' }} />
							</div>
							<div className={`nav-elements  ${showNavbar && 'active'}`}>
								<ul>
									<li>
										<a href='../#pricing' className='no-underline'>
											Ceník
										</a>
									</li>
									<li>
										<a href='../#about' className='no-underline'>
											O nás
										</a>
									</li>
									<li>
										<a href='../#services' className='no-underline'>
											Aplikace
										</a>
									</li>
									<li>
										<a href='../#contact' className='no-underline'>
											Kontakt
										</a>
									</li>
									<li>
										<a href='/galerie' className='no-underline'>
											Galerie
										</a>
									</li>
									<li>
										{Cookies.get('token') ? (
											<a href='/admin/dashboard' className='no-underline'>
												Administrace
											</a>
										) : (
											<a href='/login' className='no-underline'>
												Přihlásit se
											</a>
										)}
									</li>
								</ul>
							</div>
						</div>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Navbar;
